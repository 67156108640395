var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "interactions" },
    [
      _c("input", {
        staticStyle: { width: "0px", height: "0px", opacity: "0" },
        attrs: { type: "text" },
        domProps: { value: _vm.chartOptions }
      }),
      _vm.view
        ? _c("highcharts", { attrs: { options: _vm.chartOptions } })
        : _c("div", [
            _c("img", {
              staticClass: "opacity-50",
              attrs: {
                src: require("@/assets/image-off-interactionsbenchmark.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "image-container-off__message" }, [
              _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
              _vm._v(" " + _vm._s(_vm.msgImgOffGraphic) + " ")
            ])
          ]),
      _c("div", { staticClass: "tab-interactions-container-separator" }, [
        _c("div", { attrs: { "data-s-9": "" } }, [
          _c(
            "table",
            { staticClass: "interactions-page-table-style" },
            [
              _c("thead", [
                _c(
                  "th",
                  {
                    staticClass: "header-table",
                    staticStyle: { "text-align": "left" }
                  },
                  [_vm._v("Page")]
                ),
                _vm.optrss == 1 ||
                _vm.optrss == 3 ||
                _vm.optrss == 4 ||
                _vm.optrss == 5
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v(" Comments ")
                    ])
                  : _vm._e(),
                _vm.optrss == 1 ||
                _vm.optrss == 3 ||
                _vm.optrss == 4 ||
                _vm.optrss == 5
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v(" Likes ")
                    ])
                  : _vm._e(),
                _vm.optrss == 4
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Videos")
                    ])
                  : _vm._e(),
                _vm.optrss == 4
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Subscribers")
                    ])
                  : _vm._e(),
                _vm.optrss == 4
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("New Subscribers")
                    ])
                  : _vm._e(),
                _vm.optrss == 1
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Shares")
                    ])
                  : _vm._e(),
                _vm.optrss == 1
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Page fans")
                    ])
                  : _vm._e(),
                _vm.optrss == 1
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("New Fans")
                    ])
                  : _vm._e(),
                _vm.optrss == 2
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Retweets")
                    ])
                  : _vm._e(),
                _vm.optrss == 2
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Favorites")
                    ])
                  : _vm._e(),
                _vm.optrss == 2
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v("Tweets")
                    ])
                  : _vm._e(),
                _vm.optrss == 2 || _vm.optrss == 3 || _vm.optrss == 5
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v(" Followers ")
                    ])
                  : _vm._e(),
                _vm.optrss == 2 || _vm.optrss == 3 || _vm.optrss == 5
                  ? _c("th", { staticClass: "header-table" }, [
                      _vm._v(" New Followers ")
                    ])
                  : _vm._e()
              ]),
              _vm._l(_vm.followers, function(item, key) {
                return _c("tbody", { key: item + key }, [
                  _c(
                    "td",
                    { staticClass: "name-page-table tableinteractions" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "openperfil",
                          on: {
                            click: function($event) {
                              return _vm.abrirpestanias(item.dscUrlRedSocial)
                            }
                          }
                        },
                        [
                          _c("b-img", {
                            staticClass: "img-thumbnail img-fluid",
                            attrs: {
                              thumbnail: "",
                              fluid: "",
                              src: item.dscLogo,
                              width: "50",
                              height: "50"
                            },
                            on: { error: _vm.errorUrlImage }
                          }),
                          _vm._v(" " + _vm._s(item.nomUsuarioRedsocial) + " ")
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.optrss == 2
                    ? _c(
                        "td",
                        {
                          staticClass: "content-items-table tableinteractions"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.addCharacter(item.retweets)) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.optrss != 2
                    ? _c(
                        "td",
                        {
                          staticClass: "content-items-table tableinteractions"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.totalcomments == 0
                                  ? _vm.addCharacter(item.comments)
                                  : _vm.addCharacter(item.totalcomments)
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "td",
                    { staticClass: "content-items-table tableinteractions" },
                    [_vm._v(" " + _vm._s(_vm.addCharacter(item.likes)) + " ")]
                  ),
                  _vm.optrss == 1
                    ? _c(
                        "td",
                        {
                          staticClass: "content-items-table tableinteractions"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.addCharacter(item.shares)) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.optrss == 4
                    ? _c(
                        "td",
                        {
                          staticClass: "content-items-table tableinteractions"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.addCharacter(item.countVideos)) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "td",
                    { staticClass: "content-items-table tableinteractions" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.addCharacter(item.audiencia_total)) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "td",
                    { staticClass: "content-items-table tableinteractions" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.addCharacter(item.audiencia_nueva)) +
                          " "
                      )
                    ]
                  )
                ])
              })
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }