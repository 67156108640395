import axios from "axios";

const base_url = process.env.VUE_APP_DEV_BACK_MONITOR;
//* this url comes from an a job.
const urlgrabar = process.env.VUE_APP_URL_GRABAR;
const { validationCodeToken } = require('../util/validateCodeToken')
const _TOKEN = sessionStorage.getItem('token')
const _REFRESH_TOKEN = sessionStorage.getItem('refreshToken')
const _IP_PUBLIC = localStorage.getItem('ip-public')
const _IP_LOCAL = localStorage.getItem('ip-local')
const headers = {
  Authorization: _TOKEN+'|'+_REFRESH_TOKEN+'|'+_IP_PUBLIC+'|'+_IP_LOCAL,
};
//*Localhost 
//const base_url = "http://localhost:3000/api/v1";

export const listPostByClient = async () => {
  try {
    const id_post = 1; // TODO : esto será llamado desde el localstorage junto con el token
    const { data } = await axios.get(
      `${base_url}/benchmark/${id_post}/post`,
      {
        headers,
      }
    );
    let temp= await validationCodeToken(data);
      return temp.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
};

export const audiencia = async (id_cuenta_red_social, id_red_social) => {
  try {
    const { data } = await axios.get(
      `${base_url}/channels/${id_cuenta_red_social}/${id_red_social}/facebook`,
      {
        headers,
      }
    );
    let temp=await validationCodeToken(data);
      return temp.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
};

export async function getPerfilByIdClient(dataSend) {
  try {
    let configAxios = {}
    configAxios.method = 'get'
    configAxios.url = base_url+ "/benchmark/"+ dataSend.opcRedSocial + "/perfileRedSocial"
    configAxios.params = {
      id_cliente: dataSend.id_client,
    }
    configAxios.headers = headers
    const { data } = await axios(configAxios)
    let temp= await validationCodeToken(data);
    return temp.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function getDetalleBeanchmark(dataSend, idCuentaRedSocial, opcRedSocial) {
  try {
    let configAxios = {};
    configAxios.method = "get"
    configAxios.url = base_url+ "/benchmark/" + idCuentaRedSocial + "/" + opcRedSocial + "/posts";
    configAxios.params = dataSend
    configAxios.headers = headers;
    const { data } = await axios(configAxios);
    let temp= await validationCodeToken(data);
      return temp.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function refreshDetalleBeanchmark(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get"
    configAxios.url = base_url+ "/benchmark/" + dataSend.nomCuentaRedSocial + "/" + dataSend.opcRedSocial + "/posts";
    configAxios.params = dataSend.data
    configAxios.headers = headers;
    const { data } = await axios(configAxios);
    let temp=await validationCodeToken(data);
      return temp.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function grabarBeanchmark(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get"
    configAxios.url = urlgrabar + "/" + dataSend.username;
    configAxios.params = dataSend.data
    const { response, status,  data } = await axios(configAxios);
    return validationCodeToken(data);
  } catch (error) {
    throw error;
  }
}

export async function searchPostsbyRedSocial(dataSend){
  try {
    let configAxios = {}
      configAxios.method = 'get',
      configAxios.url = base_url+ '/benchmark'+"/searchPostsbyRedSocial"
      configAxios.params = dataSend
      configAxios.headers = headers
      const { data } = await axios(configAxios);
      let temp= await validationCodeToken(data);
      return temp.data;

  } catch (error) {
    console.log(error);
    return -1;
  }
}