import axios from "axios";

const base_url = process.env.VUE_APP_DEV_BACK_MONITOR;//* BACK OVERVIEW
//* this url comes from an a job.
const url_record_benchmark = process.env.VUE_APP_URL_GRABAR;
import { getCredentialsHeader } from "../util/helper";
//*Localhost
//const base_url = "http://localhost:3000/api/v1"; //* BACK OVERVIEW

export async function getPerfilByIdClient(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url =
      base_url + "/benchmark/" + dataSend.opcRedSocial + "/perfileRedSocial";
    configAxios.params = {
      id_cliente: dataSend.id_client,
    };
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return data.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function getDetalleBeanchmark(
  dataSend,
  idCuentaRedSocial,
  opcRedSocial
) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url =
      base_url +
      "/benchmark/" +
      idCuentaRedSocial +
      "/" +
      opcRedSocial +
      "/posts";
    configAxios.params = dataSend;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return data.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function refreshDetalleBeanchmark(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url =
      base_url +
      "/benchmark/" +
      dataSend.nomCuentaRedSocial +
      "/" +
      dataSend.opcRedSocial +
      "/posts";
    configAxios.params = dataSend.data;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return data.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function grabarBeanchmark(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = url_record_benchmark + "/" + dataSend.username;
    configAxios.params = dataSend.data;
    const { response, status, data } = await axios(configAxios);
    return data; // * Send data only because the result of the object is { body: ..., success: ... }
  } catch (error) {
    throw error;
  }
}

export async function searchPostsbyRedSocial(dataSend) {
  try {
    let configAxios = {};
    (configAxios.method = "get"),
      (configAxios.url = base_url + "/benchmark" + "/searchPostsbyRedSocial");
    configAxios.params = dataSend;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return data.data;
  } catch (error) {
    console.log(error);
    return -1;
  }
}
