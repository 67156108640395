<template>
  <div class="detail-content-box">
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div v-if="perfiles && perfiles.length > 0">
      <div>
        <div class="mt-4 mb-3 pl-1">
          <span class="text-lg text-secondary font-weight-bold">
            Select profiles to compare
          </span>
          <i
            class="fa fa-info-circle text-skygray text-lsm"
            id="general-info"
          ></i>
          <cp-tooltip-white-custom
            target="general-info"
            message="Update: Every 24 hours"
            padding-container="30px"
          />
        </div>
        <div class="m-0 pl-0 pr-0 row">
          <div class="row col-12 pr-0 pl-0 w-100">
            <div
              v-for="(item, index) in Followers"
              :key="item + index"
              class="data-s-3 col-2"
            >
              <div>
                <div class="m-0 row justify-content-center data-s-3">
                  <div>
                    <div class="place-profile-image">
                      <i
                        class="fas fa-times icono-profile"
                        @click.prevent="deleteTask(index)"
                      ></i>
                      <b-img
                        class="imagen-nuevo-profile"
                        v-bind:src="item.dscLogo"
                        @error="errorUrlImage"
                      ></b-img>
                    </div>
                    <div class="texto text-xs text-color-text text-center">
                      {{ item.nomUsuarioRedsocial }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="label-name"></div> -->
            </div>
            <div
              v-for="i in 5 - Followers.length"
              :key="i"
              class="data-s-3 col-2 pl-2 pr-2"
            >
              <div
                class="place-profile place-profile-box p-4 d-flex align-item"
                @click="viewProfile = !viewProfile"
              >
                <div
                  class="m-0 d-flex flex-column align-items-center justify-content-center"
                >
                  <i
                    :class="['fab fa-' + benchsel, 'i-' + benchsel]"
                    class="ico-profile mb-3"
                  ></i>
                  <span class="text-xxs text-color-text">Place Profile</span>
                </div>
              </div>
              <!-- <div class="label-name"></div> -->
            </div>
            <div class="col-2">
              <b-button
                :class="['button-' + benchsel, 'profile-button']"
                @click="openModalAddProfile"
              >
                <i class="fas fa-plus text-xs"></i>
                <span class="text-xs ml-1">Add new profile</span>
              </b-button>
              <b-button
                :class="['clear-button', 'profile-button']"
                block
                @click.prevent="deleteCompleted"
                :disabled="Followers.length === 0"
                ><i class="far fa-trash-alt" font-scale="1"></i> Clear
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b-modal
          v-model="addProfile"
          ref="addProfile"
          size="lg"
          title="Add Profile"
          hide-footer
          id="modalbenchadd"
          header-class="header-modal-profile"
          no-close-on-backdrop
        >
          <close-button @close="closeModal" />
          <template #modal-title>
            <div class="d-flex align-items-center pt-5">
              <h4 class="mb-0 mt-0 text-lsm text-secondary">Add Profile</h4>
              <span
                class="text-secondary ml-1 cursor-pointer"
                v-if="benchsel == 'youtube'"
              >
                <i
                  id="youtube1"
                  class="fa fa-info-circle text-sm text-skygray"
                ></i>
                <cp-tooltip-white-custom
                  target="youtube1"
                  message="Only accounts of this type should be registered www.YouTube.com/@...."
                  padding-container="15px"
                />
              </span>
              <span
                class="text-secondary ml-1 cursor-pointer"
                v-if="benchsel == 'youtube'"
              >
                <i
                  id="youtube2"
                  class="fa fa-info-circle text-sm text-skygray"
                ></i>
                <cp-tooltip-white-custom
                  target="youtube2"
                  message="Channel information will be displayed according to the permissions granted by the channel creator."
                  padding-container="10px 7px"
                />
              </span>
            </div>
          </template>
          <p class="title-add-profile">Add Profile</p>
          <div class="separacion_detalle">
            <b-input
              type="text"
              :placeholder="descripction"
              size="sm"
              v-model="urlText"
              required
              id="desc-benchtem"
              class="add-input text-xs text-secondary"
            />
            <b-button
              variant="primary"
              size="sm"
              class="btn-grabar"
              @click.prevent="grabar"
              v-b-modal.modal-multi-3
            >
              Add
            </b-button>
          </div>
          <span v-if="benchsel == 'youtube'">
            <cp-warning-message message="You can only link Youtube channels." />
          </span>
        </b-modal>
        <b-modal
          v-model="viewProfile"
          ref="viewProfile"
          size="lg"
          title="Social Network Profile"
          id="modalbenchselect"
          scrollable
          ok-only
          no-close-on-backdrop
        >
          <template v-slot:modal-header-close>
            <cp-icon-close-x />
          </template>
          <template #modal-title>
            <h4>Social Network Profile</h4>
          </template>
          <b-row class="text-center mt-3">
            <b-col
              md="3"
              @click.prevent="detalle(item.nomCuentaRedSocial)"
              v-for="(item, key) in perfilesview"
              :key="item + key"
            >
              <b-overlay :show="show" class="d-inline-block mb-2 mt-2">
                <b-img
                  fluid
                  v-bind:src="item.dscLogo"
                  alt="Image 1"
                  @error="errorUrlImage"
                >
                </b-img>
              </b-overlay>
              <p
                style="font-family: Oxygen; font-size: 12px; font-weight: bold"
                class="mb-3"
              >
                {{ item.nomUsuarioRedsocial }}
              </p>
            </b-col>
          </b-row>
        </b-modal>
      </div>
      <!-- Info social networks -->
      <div id="detallesOpc" v-show="detallesOpc">
        <div v-if="idCondRedSocial === 1" class="separacion">
          <b-row align-h="around" class="row">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Page Fans
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_total) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    New Fans
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{
                          numFormatter(
                            item.audiencia_nueva > 0 ? item.audiencia_nueva : 0
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Comments
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{
                          numFormatter(
                            item.totalcomments == 0
                              ? item.comments
                              : item.totalcomments
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row class="separacion_detalle">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Shares
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.shares) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Likes
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.likes) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <!-- <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Post
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.totalPost) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </b-row>
        </div>
        <div v-if="idCondRedSocial === 2" class="separacion">
          <b-row align-h="around" class="row">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Followers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_total) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    New Followers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_nueva) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Retweets
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.retweets) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row align-h="around" class="separacion_detalle">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Favorites
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.likes) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Tweets
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.totalPost) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4"></div>
          </b-row>
        </div>
        <div v-if="idCondRedSocial === 3" class="separacion">
          <b-row align-h="around" class="row">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Followers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_total) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    New Followers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_nueva) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Comments
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{
                          numFormatter(
                            item.totalcomments == 0
                              ? item.comments
                              : item.totalcomments
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row align-h="around" class="separacion_detalle">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Likes
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.likes) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <!-- <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Posts
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.totalPost) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-4"></div>
          </b-row>
        </div>
        <div v-if="idCondRedSocial === 4" class="separacion">
          <b-row align-h="around" class="row">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Subscribers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_total) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    New Subscribers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_nueva) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Comments
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{
                          numFormatter(
                            item.totalcomments == 0
                              ? item.comments
                              : item.totalcomments
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row align-h="around" class="separacion_detalle">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Likes
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.likes) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Dislikes
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.dislikes) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Videos
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.countVideos) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </div>
        <div v-if="idCondRedSocial === 5" class="separacion">
          <b-row align-h="around" class="row">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Page Followers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.audiencia_total) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Page New Followers
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{
                          numFormatter(
                            item.audiencia_nueva > 0 ? item.audiencia_nueva : 0
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Comments
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-right">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.comments) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row class="separacion_detalle">
            <div class="col-4">
              <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Likes
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-center">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.likes) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <!--  <div class="benchmark-report-container">
                <div data-s-7 class="card-container w-100">
                  <div class="font-weight-bold mb-2 text-secondary text-lsm">
                    Post
                  </div>
                  <div
                    class="row"
                    v-for="(item, key) in Followers"
                    :key="item + key"
                  >
                    <div class="col-md-6">
                      <span class="text-color-text text-xs">
                        {{ item.nomUsuarioRedsocial }}
                      </span>
                    </div>
                    <div class="col-md-6 text-center">
                      <span class="text-color-text text-xs font-weight-bold">
                        {{ numFormatter(item.totalPost) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-4"></div>
          </b-row>
        </div>
        <div class="tabs_graf">
          <b-card no-body>
            <b-tabs content-class="mt-3" fill card>
              <b-tab title="Audience">
                <h4 class="tstabs">
                  Audience
                  <i
                    class="fa fa-info-circle tlt-ico"
                    v-b-tooltip
                    title="Metrics are obtained every 24 hours."
                  ></i>
                </h4>
                <Audence :followers="this.Followers"></Audence>
              </b-tab>
              <b-tab v-if="idCondRedSocial != 5" title="Post">
                <h4 class="tstabs">
                  Post by day
                  <i
                    class="fa fa-info-circle tlt-ico"
                    v-b-tooltip
                    title="Constant monitoring of the metrics of the last 25 posts is maintained"
                  ></i>
                </h4>
                <Post
                  ref="Posts"
                  @update-topcontent="updateTopContent($event)"
                  :followers="this.Followers"
                  :timezone-id="timeZoneId"
                  :countryZone="strCountryZone"
                ></Post>
              </b-tab>
              <b-tab title="Top Content" v-if="idCondRedSocial != 5">
                <TopContent
                  class="topcon"
                  ref="topcontent"
                  :postsendTopcontent="postsendTopcontent"
                  :followers="this.Followers"
                  :benchsel="benchsel"
                  :countryZone="strCountryZone"
                ></TopContent>
              </b-tab>
              <b-tab title="Interactions" v-if="idCondRedSocial != 5">
                <h4 class="tstabs">
                  Post Interactions
                  <i
                    class="fa fa-info-circle tlt-ico"
                    v-b-tooltip
                    title="Constant monitoring of the metrics of the last 25 posts is maintained"
                  ></i>
                </h4>
                <Interactions
                  :optrss="opcRedSocial"
                  :followers="this.Followers"
                ></Interactions>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>
    <cp-warning-info
      v-else
      info-text="You must add an account on Overview before using Benchmark."
    />
    <div>
      <!-- MODAL ALERT GLOBAL -->
      <IscModalAlerts
        v-model="showAlert"
        :icon="icon"
        :iconColor="iconColor"
        :title="title"
        :paragraph="paragraph"
        :buttonMessage="buttonMessage"
        @sendResult="resultModalAlerts"
        :noCloseBackdrop="true"
        :showBtn="showBtn"
        :showMainIcon="showMainIcon"
        :showBtnClose="showBtnClose"
      >
      </IscModalAlerts>
    </div>
  </div>
</template>
<script>
import {
  CpWarningInfo,
  CpTooltipWhiteCustom,
  CpWarningMessage,
  loaderCustom,
  IscModalAlerts,
  CloseButton,
  CpIconCloseX,
  UserObjMixin,
  AlertSetting,
  Helpers
} from "@isc/styleguide";
import * as BenchmarkService from "@/services/benchmark.service";
import Audence from "../Grafico/audence.vue";
import Interactions from "../Grafico/Interactions.vue";
import Post from "../Grafico/Post.vue";
import TopContent from "../Grafico/TopContent.vue";
import moment from "moment";

export default {
  name: "benchSelect",
  components: {
    Audence,
    Post,
    TopContent,
    Interactions,
    loaderCustom,
    CpWarningInfo,
    CpTooltipWhiteCustom,
    CpWarningMessage,
    IscModalAlerts,
    CloseButton,
    CpIconCloseX,
  },
  mixins: [UserObjMixin, AlertSetting],
  data() {
    return {
      hover: false,
      detallesOpc: false,
      visibilityLoader: false,
      show: false,
      profiles: "",
      urlfinal: "",
      partetres: "",
      signo: "",
      addProfile: false,
      viewProfile: false,
      idCondRedSocial: this.opcRedSocial,
      equalred: "",
      placeholderValue: this.descripction,
      porciones: "",
      title: "",
      mensaje: "cargando...",
      Followers: [],
      imagen: "https://acegif.com/wp-content/uploads/loading-12.gif",
      loading: true,
      errored: false,
      perfiles: [],
      perfilesview: [],
      postsendTopcontent: [],
      id_client: 0,
      headers: {},
      urlText: "",
      showMessage: false,
      showAlert: false,
      icon: "",
      iconColor: "",
      title: "",
      paragraph: "",
      buttonMessage: "",
      timeZoneId: "",
      strCountryZone: "",
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  props: {
    benchsel: String,
    opcRedSocial: Number,
    descripction: String,
    validador: String,
    RangeDate: {
      type: Array,
      required: true,
    },
  },
  watch: {
    urlText(value) {
      this.urlText = decodeURIComponent(value.replace(/\+/g, " "));
    },
  },
  filters: {
    currencydecimal(value) {
      return value.toFixed(2);
    },
  },
  async mounted() {
    this.visibilityLoader = true;
    await this.setCurrentObjUser(); // function from styleguide(Mixin)
    const _TOKEN = sessionStorage.getItem("token");
    this.headers.Authorization = _TOKEN;
    const objUsuario = this.userInfo;
    this.id_client = objUsuario.id_cliente;
    this.timeZoneId = objUsuario.ID_ZONA_HORARIA;
    this.strCountryZone = objUsuario.TIME_ZONE;
    await this.perfilesRed();
    if (this.perfilesview.length == 0) this.showMessage = true;
    else this.showMessage = false;
  },
  methods: {
    async grabar() {
      this.visibilityLoader = true;
      try {
        let msgSuccessAddedAccount =
          "New account added, it will take around 24 hours to start seeing the results.";
       // this.suucessDisplay("loading");
        if (this.urlText) {
          if (this.opcRedSocial == 4) {
            //Validacion de URL YOUTUBE
            const regex = /https?:\/\/(www\.)?youtube\.com\/(@[\w\-\_\+\=\?\&\.\,\!\~\*\(\)\[\]\{\}\|\@\#\$\%\^\&\*\+\-\~]+)/;
            let str = this.urlText;
            let validado = regex.test(str);
            //
            if (validado == true) {
              let porciones = str.split("@");
              let username = porciones[porciones.length - 1];
              let dataRequest = {};
              dataRequest.username = username;
              dataRequest.data = {
                idRedSocial: this.opcRedSocial,
                idCliente: this.id_client,
              };
              const response = await BenchmarkService.grabarBeanchmark(
                dataRequest
              );

              if (response.sucess == true) {
                this.suucessDisplay("success", msgSuccessAddedAccount);
                this.perfilesRed();
              } else {
                this.suucessDisplay("notsave", verificar.body);
              } 

              this.limpiar_registro();
            } else if (validado == false) {
              this.suucessDisplay("urlincorrecta");
              this.limpiar_registro();
            }
          } else if (this.opcRedSocial == 5) {
            //Validacion de URL LINKEDIN
            const regex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company)/;
            let str = this.urlText;
            let validado = regex.test(str);
            //
            if (validado == true) {
              let porciones = str.split("/");
              let username = porciones[porciones.length - 1];
              if (username.length == 0) {
                //
                username = porciones[porciones.length - 2];
              }
              let dataRequest = {};
              dataRequest.username = username;
              dataRequest.data = {
                idRedSocial: this.opcRedSocial,
                idCliente: this.id_client,
              };
              const response = await BenchmarkService.grabarBeanchmark(
                dataRequest
              );

              if (response.sucess == true) {
                this.suucessDisplay("success", msgSuccessAddedAccount);
                this.perfilesRed();
              } else {
                this.suucessDisplay("notsave", verificar.body);
              }
              this.limpiar_registro();
            }
          } else {
            this.porciones = this.urlText.split("/");
            this.partetres = this.porciones[3];

            if (this.partetres.search("lang".toString()) != -1) {
              const validadoresURL = this.partetres.split("?");
              this.urlfinal = validadoresURL[0];
            } else {
              var numero = this.partetres.search("-".toString());
              if (numero != -1) {
                let validadoresURL1 = this.partetres.split("-");
                this.urlfinal = validadoresURL1.pop();
              } else {
                this.urlfinal = this.porciones[3];
              }
            }
            var n = this.urlText.search(this.validador.toString());
            if (n != -1) {
              let dataRequest = {};
              dataRequest.username = this.urlfinal;
              dataRequest.data = {
                idRedSocial: this.opcRedSocial,
                idCliente: this.id_client,
              };
              let response = {};

              response = await BenchmarkService.grabarBeanchmark(dataRequest);

              if (response.sucess == true) {
                this.suucessDisplay("success", msgSuccessAddedAccount);
                this.perfilesRed();
              } else {
                this.suucessDisplay("notsave", response.body);
              }
              this.limpiar_registro();
            } else {
              this.suucessDisplay("malurl");
              this.limpiar_registro();
            }
            this.limpiar_registro();
            this.perfilesRed();
          }
        } else {
          this.errorModal("Wrong URL");
        }
        this.limpiar_registro();
        this.perfilesRed();
      } catch (error) {
        let messageError = error?.response?.data.message ?? "Server fails.";
        this.errorModal(messageError);
      } finally {
        this.visibilityLoader = false;
      }
    },
    showButtonsModal(){
      this.showBtn = true;
      this.showMainIcon = true;
      this.showBtnClose = true;
    },
    async perfilesRed() {
      try {
        this.visibilityLoader = true;
        const resultGetProfile = await BenchmarkService.getPerfilByIdClient({
          id_client: this.id_client,
          opcRedSocial: this.opcRedSocial,
        });

        this.perfilesview = [];
        this.perfiles = resultGetProfile.RedSocialProfile;
        if (!this.perfiles) this.perfiles = [];
        //console.log("PROFILES ===> ", this.perfiles);
        this.perfiles.forEach((element) => {
          let temp = this.Followers.filter(
            (item) => item.nomCuentaRedSocial == element.nomCuentaRedSocial
          );
          if (temp.length == 0) this.perfilesview.push(element);
        });
      } catch (error) {
        console.log("[Error] in function perfilesRead");
        this.errorModal("Server fails to get profile client.");
      } finally {
        this.visibilityLoader = false;
      }
    },
    async detalle(idCuentaRedSocial) {
      try {
        this.visibilityLoader = true;
        let dataRequest = {
          id_cliente: this.id_client,
          fecha_ini: this.RangeDate[0],
          fecha_fin: this.RangeDate[1],
          timezone_id: this.timeZoneId,
        };
        let response = await BenchmarkService.getDetalleBeanchmark(
          dataRequest,
          idCuentaRedSocial,
          this.opcRedSocial
        );
        let metrics = response.comentario_pub[0];
        let obj = {
          uid: metrics.uid,
          fec_creacion: metrics.fec_creacion,
          nomUsuarioRedsocial: metrics.nomUsuarioRedsocial,
          audiencia_total: metrics.audiencia_total,
          audiencia_nueva: metrics.audiencia_nueva,
          comments: metrics.comments,
          totalcomments: metrics.totalcomments,
          totalPost: metrics.totalPost,
          countVideos: metrics.countVideos,
          likes: metrics.likes,
          dislikes: metrics.dislikes || 0,
          likes_perdidos: metrics.likes_perdidos,
          views: metrics.views,
          vistas_total: metrics.vistas_total,
          shares: metrics.shares,
          dscUrlRedSocial: metrics.dscUrlRedSocial,
          dscLogo: metrics.dscLogo,
          retweets: metrics.retweet || 0,
          id_red_social: metrics.id_red_social,
          nomCuentaRedSocial: metrics.nomCuentaRedSocial,
        };
        this.Followers.push(obj);
        this.perfilesRed();
        this.detallesOpc = true;
        this.$refs["viewProfile"].hide();
        if (this.$refs.Posts !== undefined) {
          await this.$refs.Posts.obtainposts(this.RangeDate);
        }
        this.visibilityLoader = false;
      } catch (error) {
        console.log("el error", error);
        this.visibilityLoader = false;
      }
    },
    async refreshDetalle(fechaini, fechafin) {
      try {
        this.visibilityLoader = true;
        this.perfilesRed();
        let tempfollows = [];
        for (const item of this.Followers) {
          let dataRequest = {
            id_cliente: this.id_client,
            fecha_ini: fechaini,
            fecha_fin: fechafin,
            timezone_id: this.timeZoneId,
          };
          let response = await BenchmarkService.refreshDetalleBeanchmark({
            data: dataRequest,
            nomCuentaRedSocial: item.nomCuentaRedSocial,
            opcRedSocial: this.opcRedSocial,
          });
          let metrics = response.comentario_pub[0];
          tempfollows.push({
            uid: metrics.uid,
            fec_creacion: metrics.fec_creacion,
            nomUsuarioRedsocial: metrics.nomUsuarioRedsocial,
            audiencia_total: metrics.audiencia_total,
            audiencia_nueva: metrics.audiencia_nueva,
            comments: metrics.comments,
            totalcomments: metrics.totalcomments,
            totalPost: metrics.totalPost,
            countVideos: metrics.countVideos,
            likes: metrics.likes,
            dislikes: metrics.dislikes || 0,
            likes_perdidos: metrics.likes_perdidos,
            views: metrics.views,
            vistas_total: metrics.vistas_total,
            shares: metrics.shares,
            dscUrlRedSocial: metrics.dscUrlRedSocial,
            dscLogo: metrics.dscLogo,
            retweets: metrics.retweet || 0,
            id_red_social: metrics.id_red_social,
            nomCuentaRedSocial: metrics.nomCuentaRedSocial,
          });
        }
        this.Followers = tempfollows;
        if (this.Followers.length > 0) {
          this.detallesOpc = true;
          if (this.$refs.Posts !== undefined) {
            await this.$refs.Posts.obtainposts(this.RangeDate);
          }
        }
        this.visibilityLoader = false;
      } catch (error) {
        console.log(error);
        this.visibilityLoader = false;
      }
    },
    deleteTask: async function(index) {
      this.visibilityLoader = true;
      this.Followers.splice(index, 1);
      this.perfilesRed();
      if (this.$refs.Posts !== undefined) {
        await this.$refs.Posts.obtainposts(this.RangeDate);
      }
      if (this.Followers.length == 0) this.detallesOpc = false;
      this.visibilityLoader = false;
    },
    deleteCompleted: function() {
      this.detallesOpc = false;
      this.perfilesRed();
      this.Followers = this.Followers.filter(function(task) {
        return task.pending;
      });
    },
    limpiar_registro() {},
    suucessDisplay: function(type, message) {
      if (type === "success") {
        this.successModal(message);
        this.urlText = "";
      } else if (type === "warning") {
        this.warningModal("Invalid token");
      } else if (type === "notsave") {
        this.errorModal(message);
      } else if (type === "malurl") {
        this.errorModal("The URL does not correspond to the social network.");
      } else if (type === "urlincorrecta") {
        this.errorModal("The URL entered is incorrect.");
      } else if (type === "cuentaVinculada") {
        this.errorModal("An account has been linked before.");
      } else if (type === "cuentaNoVinculada") {
        this.errorModal("There are no accounts associated with the account");
      } else if (type === "nodatafound") {
        this.errorModal("No data found");
      } else if (type === "loading") {
        this.loadingModal("loading URL!");
      }
    },
    returndates(fechaini, fechafin) {
      let datenow = new Date();
      let temnow = moment(datenow).format("HH:mm:ss");
      let tempini = moment(fechaini).format("YYYY/MM/DD") + " " + temnow;
      let tempfin = moment(fechafin).format("YYYY/MM/DD") + " " + temnow;
      return {
        fechaini: tempini,
        fechafin: tempfin,
      };
    },
    updateTopContent(e) {
      this.postsendTopcontent = e;
      (
        this.$refs.topcontent || { returnTopContents: () => {} }
      ).returnTopContents(e) || {};
    },

    errorUrlImage(event) {
      // changing for default image.
      event.target.src = require("../../assets/off-image_170x170.png");
    },
    closeModal(value) {
      this.addProfile = value;
    },
    openModalAddProfile() {
      this.addProfile = true;
      this.urlText = "";
    },
  },
};
</script>
<style src="../shared/style/seletectstyle.css"></style>
